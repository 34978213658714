<template>
   <div>
    <router-view/>
    <KakaoPopup/>
   </div>
</template>

<script>
import index from './views/app/index'
import KakaoPopup from "@/container/KakaoPopup";
export default {
  name: 'App',
  components: {
    KakaoPopup,
    index
  },
}
</script>

<style>
* {
  font-family: 'Pretendard', sans-serif !important;
}
</style>
