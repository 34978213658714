import {useAuthStore} from "@/stores";

export default {
  computed:{
    user() {
        return this.authStore.user == null ? false : this.authStore.user
    }
  },
  data(){
      return {
          authStore : useAuthStore(),
          logoutPath: '/user'
      }
  }
}
