<template>
  <main id="app" class="main-screen-sec">
    <Header/>
    <router-view/>
    <Footer/>
  </main>
</template>
<script>
import Header from "@/layout/app/partials/Header";
import Footer from "@/layout/app/partials/Footer";

export default {
  components: {Header,  Footer},
  async created() {

  }
}
</script>