import {JwtAxios} from "@/packaging";
import dayjs from "dayjs";

export const naverPlaceAPI = {
    async GetNaverPlace(page, limit=50, startDate="", endDate="", keywordType="", keyword=""){
        if(startDate=="" || endDate =="") {
            startDate='1990-01-01'
            endDate= dayjs().format('YYYY-MM-DD')
        }
        const responseModel = await JwtAxios.get(`/NaverPlace/GetNaverPlace?page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&keywordType=${keywordType}&keyword=${keyword}`);
        return responseModel
    },
    async GetNaverPlaceCount(startDate="",endDate="", keywordType="",keyword=""){
        if(startDate=="" || endDate =="") {
            startDate='1990-01-01'
            endDate= dayjs().format('YYYY-MM-DD')
        }
        const responseModel = await JwtAxios.get(`/NaverPlace/GetNaverPlaceCount?startDate=${startDate}&endDate=${endDate}&keywordType=${keywordType}&keyword=${keyword}`);
        return responseModel
    },

    async GetNaverPlaceTotalCount(){
        const responseModel = await JwtAxios.get(`/NaverPlace/GetNaverPlaceTotalCount`);
        return responseModel
    },
}