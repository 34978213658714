<template>
  <div v-if="showPopup" class="kakaoPopup">

  <a href="tel:0508-4265-4300" >
    <div class="kakaoSubTitle">
      <a href="#" class="kakaoClose" @click="KakaoPopupClose24">x</a>
      <p class="kakaoName">DB구매 문의</p>
      <p class="kakaoPhone">0507-1415-8341</p>
      <span style="font-size:14px">연중무휴 24시간 상담가능</span>
    </div>
  </a>
  <a href="/license">
    <div class="kakaoSubTitle">
      <p class="kakaoPhone">라이센스 구매</p>
    </div>
  </a>
  <a class="naver" href="javascript:;" @click="GetKakaoChat"><img src="/assets/images/kakao.jpg" alt="카카오톡"></a>

  </div>
  <div v-else class="kakaoPopup2">
      <img @click="showPopup=true" src="/assets/images/small_kakao.png"/>
  </div>
</template>

<script>
export default {
  name: "KakaoPopup",
  mounted() {
    this.checkPopupState();
  },
  data(){
    return {
      showPopup: true
    }
  },
  methods:{
    GetKakaoChat(){
      window.open('https://open.kakao.com/o/s8eECVVg', 'kakaoChannel', 'width=1000, height=640')
    },
    getTodayDate() {
      const today = new Date();
      return `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
    },
    checkPopupState() {
      const storedDate = localStorage.getItem('popupDate');
      if (storedDate === this.getTodayDate()) {
        this.showPopup = false;
      }
    },
    KakaoPopupClose24() {
      this.showPopup = false
      localStorage.setItem('popupDate', this.getTodayDate());
      this.closePopup();
    }
  }
}
</script>

<style scoped>

.kakaoPopup{
  position:fixed;
  z-index:10000;
  bottom:40px;
  right:10px;
  width:180px;
  display: none; /* 기본은 숨김 */
}
.kakaoPopup2{
  position:fixed;
  z-index:10000;
  bottom:20px;
  right:20px;
  cursor: pointer;
  display: none; /* 기본은 숨김 */
}



.kakaoPopup .kakaoClose {
  position: absolute;
  left: 162px;
  top: 0px;
  color: red;
  font-size: 16px;
  cursor: pointer;
  transition: transform 0.2s ease, color 0.2s ease; /* 부드러운 확대 및 색상 변경 */
}

.kakaoPopup .kakaoClose:hover {
  transform: scale(1.2); /* 호버 시 1.2배로 확대 */
  color: darkred; /* 색상도 살짝 어둡게 변경 */
}

.kakaoPopup  .kakaoSubTitle{
  background-color: rgb(255, 255, 255);
  padding: 5px 3px;
  border-radius: 10px;
  border: 2px solid rgb(239, 239, 239);
  margin-bottom: 5px;
  text-align: center;
  color:black;
}
.kakaoPopup .kakaoName {
  font-size:16px;
  margin:0px 0px 3px;
  color:black;
}
.kakaoPopup .kakaoPhone {
  font-size:18px;
  font-weight:bold;
  color: rgb(195, 66, 255);
  margin:0px 0px 3px;
}

@media (min-width: 1800px) {
  .kakaoPopup {
    display: block;
  }
  .kakaoPopup2 {
    display: block;
  }
}
</style>