<template>
  <b-modal ref="loginModal" hide-footer title="로그인">
    <div>
      <label>아이디</label><b-input ref="account" :readonly="isCheck" @focusin="isCheck=false"  v-model="loginModel.account" placeholder="아이디를 입력해 주세요."/>
      <label>비밀번호</label><b-input :readonly="isCheck" @focusin="isCheck=false"   @keyup.enter="save"  type="password" v-model="loginModel.password" placeholder="비밀번호를 입력해 주세요."/>
    </div>
    <div>
      <b-button @click="save" variant="success" style="background: rgb(195, 66, 255); border-color: rgb(195, 66, 255)" class="mt-2 w-100 text-center">로그인</b-button>
    </div>
  </b-modal>
</template>

<script>

import {accountAPI} from "@/api";
export default {
  name: "LoginModal",
  props:['loginInit'],
  data(){
    return {
      isCheck: true,
      loginModel:{
        account:'',
        password:''
      }
    }
  },
  methods:{
    async save(){
      const userModel = await this.authStore.login(this.loginModel.account, this.loginModel.password)
      if(userModel.success == true){
        this.loginInit()
        this.close()
      }
      else {
        alert(userModel.errorMessage)
      }
    },
    open(){
      this.isCheck = true
      this.loginModel = {
        account:'',
        password:''
      }
      this.$refs.loginModal.show()
      setTimeout(() => {
        this.$refs.account.focus();
      }, 200);
    },
    close(){
      this.$refs.loginModal.hide()
    }
  }

}
</script>

<style scoped>
.form-control[readonly] {
  background: white;
}
</style>