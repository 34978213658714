<template>
  <nav class="navbar navbar-expand-lg navbar-light nav-sec">
    <div class="container">
      <router-link to="/">
        <a class="navbar-brand" href="#">
          <img src="/assets/images/logo.png" style="width:32px;cursor: pointer" alt="">
          <label class="mx-1" style="cursor: pointer"><span class="fw-bold">DB</span> ROBOT</label>
        </a>
      </router-link>
      <button class="navbar-toggler" v-b-toggle.navbarSupportedContent>
        <span class="navbar-toggler-icon"></span>
      </button>
      <b-collapse class="navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav m-auto">
          <li class="nav-item">
            <router-link to="/database/openmarket" :key="$route.fullPath" >
              <a class="nav-link normal-font" :class="{'active' : $route.path =='/database/openmarket'}"  href="/database/openmarket">오픈마켓 DB</a>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/database/office" :key="$route.fullPath" >
              <a class="nav-link normal-font" :class="{'active' : $route.path =='/database/office'}"  href="/database/office">사업자 DB</a>
            </router-link>
          </li>
        </ul>
        <div class="right-nav-sec">
          <div v-if="isLogin == false">
            <a @click="loginModalOpen" class="small-font">
              로그인
            </a>
            <router-link to="/signup">
              <a href="#" class="small-font">회원가입</a>
            </router-link>
          </div>
          <div v-else>
            <a @click="logout" class="small-font">
              로그아웃
            </a>
            <router-link to="/mypage" >
              <a href="#">
                마이페이지
              </a>
            </router-link>
          </div>
          <router-link to="/faq">
            <a href="#">
              FAQ
            </a>
          </router-link>
          <router-link to="/notice">
            <a href="#">
              공지사항
            </a>
          </router-link>
          <router-link to="/license">
            <a class="d-btn-a" href="#">
              <b-button style="width:160px; height: 40px; background: rgba(195, 66, 255, 1); border-radius: 100px; border-color: rgba(195, 66, 255, 1)">
                이용권 구매
              </b-button>
            </a>
          </router-link>
        </div>
      </b-collapse >
    </div>
    <loginModal :loginInit="loginInit" ref="loginModal"/>
  </nav>
</template>

<script>
import loginModal from './Modals/LoginModal'

export default {
  name: "Hearder",
  components:{
    loginModal
  },
  data(){
    return {
      isLogin: false,
      loginModel : null
    }
  },
  async created() {
    await this.loginInit()
  },
  watch: {
    async user(data){
      if(data == false){
        this.isLogin = false
      }
    }
  },
  methods:{
    logout(){
      this.authStore.logout()
      this.isLogin = false
    },
    async loginInit(){
      var setupUserModel = await this.authStore.setupUser()
      if(setupUserModel != undefined && setupUserModel != null) {
        this.isLogin = true
        this.loginModel = setupUserModel
      }
    },
    loginModalOpen(){
      this.$refs.loginModal.open()
    }
  }
}
</script>

<style scoped>
.small-font{
  font-size: 13px;
}
.normal-font {
  font-size: 16px;
}
</style>