<template>
  <div>
  <div class="under-footer-sec">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="under-footer-col">
            <div class="f-logo">
              <a href="#">
                <img src="/assets/images/logo2.png" alt="">
              </a>
            </div>
            <div class="f-content">
              <div class="f-content-inner">
								<span>
									대표: 김지환
								</span>
                <span>
									사업자번호: 425-81-02676
								</span>
                <span>
									통신판매업신고번호: 2022-서울송파-0915
								</span>
                <span class="border-0">
									개인정보책임자: 이성영
								</span>
              </div>
              <p>경기도 성남시 수정구 위례서일로 34, 성희프라자 410호</p>
              <h6>Copyright © 2019 (주)데이터봇 All rights Reserved.</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>